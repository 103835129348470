import React from 'react';
import { PageTemplate } from './page-template';
import * as dataSource from '../data';
import { generateThumbnailData } from '../helpers/generateThumbnailData';
import { generateRandomSummaryItems } from '../helpers/summaryItems';

const ServicesPage = props => {
  const {
    pageContext: { name, imgUrl, thumbnails },
  } = props;

  const nameArray = name.split('-');
  nameArray.forEach((nm, idx) => {
    if (idx > 0) {
      nameArray[idx] = `${nm.substr(0, 1).toUpperCase()}${nm.substr(1)}`;
    }
  });

  const pageName = nameArray.join('');
  const pageData = dataSource[pageName];

  const thumbnailData = generateThumbnailData(thumbnails);
  const rightSideBody = generateRandomSummaryItems(
    thumbnailData,
    pageData.heading
  );

  return (
    <PageTemplate
      title={pageData.heading}
      heading={pageData.heading}
      body={pageData.body}
      imgUrl={imgUrl}
      rightSide={rightSideBody}
    />
  );
};

export default ServicesPage;
